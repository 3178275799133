import { createApiInstance } from '@/infrastructure/apis/apiInstance'

export default {
  listCommandExecutionEvents: async ({
    pageIndex,
    pageSize,

    textSearch,
    filterSuccessful,
    filterTypeName,
    minimumCreatedOn,
    maximumCreatedOn,
    actorId,
    minimumExecutionSeconds,
    maximumExecutionSeconds,
    minimumTotalDuration,
    maximumTotalDuration,
  }) => {
    const parts = []

    if (pageIndex !== undefined) parts.push(`pageIndex=${pageIndex}`)
    if (pageSize !== undefined) parts.push(`pageSize=${pageSize}`)

    if (textSearch !== undefined) parts.push(`textSearch=${textSearch}`)
    if (filterSuccessful !== undefined) parts.push(`filterSuccessful=${filterSuccessful}`)
    if (filterTypeName !== undefined) parts.push(`filterTypeName=${filterTypeName}`)
    if (minimumCreatedOn !== undefined) parts.push(`minimumCreatedOn=${minimumCreatedOn}`)
    if (maximumCreatedOn !== undefined) parts.push(`maximumCreatedOn=${maximumCreatedOn}`)
    if (actorId !== undefined) parts.push(`actorId=${actorId}`)
    if (minimumExecutionSeconds !== undefined) parts.push(`minimumExecutionSeconds=${minimumExecutionSeconds}`)
    if (maximumExecutionSeconds !== undefined) parts.push(`maximumExecutionSeconds=${maximumExecutionSeconds}`)
    if (minimumTotalDuration !== undefined) parts.push(`minimumTotalDuration=${minimumTotalDuration}`)
    if (maximumTotalDuration !== undefined) parts.push(`maximumTotalDuration=${maximumTotalDuration}`)

    const query = parts.join('&')
    // console.debug({ query })

    const response = await createApiInstance().get(`/Admin/CommandExecutionEvents?${query}`)
    // console.debug({ response })
    return { data: response.data, headers: response.headers }
  },
}
