import { computed, ref, watch } from '@vue/composition-api'

export default function debounce(fn, delay) {
  let timeoutID = null
  return function () {
    clearTimeout(timeoutID)
    const args = arguments
    const that = this
    timeoutID = setTimeout(function () {
      fn.apply(that, args)
    }, delay)
  }
}

export function useDebouncedAutoSavedInputFieldRef({ initialValue, saveFunc, delay }) {
  if (!saveFunc) throw new Error('saveFunc is required in useDebouncedAutoSavedInputFieldRef')

  const defaultDelay = 500

  const val = ref(initialValue)

  const isSavingCount = ref(0)
  const doneCount = ref(0)

  watch(
    val,
    debounce(async (newVal) => {
      isSavingCount.value++
      try {
        await saveFunc(newVal)
        doneCount.value++
        setTimeout(() => {
          doneCount.value--
        }, 3000)
      } finally {
        isSavingCount.value--
      }
    }, delay || defaultDelay)
  )

  const isSaving = computed(() => isSavingCount.value > 0)
  const isShortlyAfterSaved = computed(() => doneCount.value > 0)

  return {
    val,
    isSaving,
    isShortlyAfterSaved,
  }
}
