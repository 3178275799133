<!--suppress HtmlUnknownAttribute, JSUnresolvedVariable -->
<script>
import commandExecutionsApi from '@/infrastructure/admin/apis/commandExecutionsApi'

export default {
  props: {
    filterProps: { type: Object, required: true },
    truncateErrorText: { type: Boolean, required: true },
    textSearch: { type: String, required: true },
  },

  data() {
    return {
      busy: false,

      tableHeaders: [
        { text: 'Created time', value: 'createdOn', sortable: false, width: '110px' },
        { text: 'Execution duration', value: 'executionTimeInSeconds', sortable: false, width: '90px' },
        { text: 'Total duration', value: 'totalTimeInSeconds', sortable: false, width: '90px' },
        { text: 'Success', value: 'successful', sortable: false, width: '90px' },
        { text: 'Type', value: 'typeName', sortable: false, width: '150px' },
        { text: 'Actor', value: 'actorContext.actorName', width: '120px', sortable: false },
        { text: 'Error', value: 'error.message', sortable: false, width: '120px' },
        { text: '', value: 'data-table-expand' },
      ],

      expandedRows: [],

      pageSizes: [5, 10, 20, 50, 100], // -1 is "All" but I actually think we don't want that as there could be thousands

      tableOptions: {
        page: 1,
        itemsPerPage: 5,
      },
      events: [],
      totalEvents: 0,
    }
  },

  watch: {
    tableOptions: {
      handler() {
        this.loadData()
      },
      deep: true,
    },
    filterProps: {
      handler() {
        this.resetTableUserSelections()
        this.loadData()
      },
      deep: true,
    },
    textSearch: {
      handler() {
        this.resetTableUserSelections()
        this.loadData()
      },
      deep: true,
    },
  },

  methods: {
    resetTableUserSelections() {
      this.expandedRows = []
      this.events = []
      this.totalEvents = 0
      this.tableOptions.page = 1
    },

    async loadData() {
      this.busy = true
      try {
        const { page, itemsPerPage } = this.tableOptions
        // const { sortBy, sortDesc, page, itemsPerPage } = this.tableOptions
        // console.debug({ sortBy, sortDesc, page, itemsPerPage })

        const result = await commandExecutionsApi.listCommandExecutionEvents(
          Object.assign(
            {},
            ...[
              {
                pageIndex: page - 1,
                pageSize: itemsPerPage,
              },
              {
                textSearch: this.textSearch,
              },
              this.filterProps,
            ]
          )
        )

        // console.debug({ result })
        const paginatedData = JSON.parse(result.headers['x-pagination'])
        // console.debug({ paginatedData })
        this.totalEvents = paginatedData.totalCount
        this.events = result.data
      } finally {
        this.busy = false
      }
    },

    prettyPrintObject(obj) {
      // console.debug('prettyPrintObject', obj)
      return JSON.stringify(obj, null, '  ')
    },
  },
}
</script>

<template>
  <v-card>
    <slot name="title-slot" v-bind="{ busy, loadData }" />

    <v-data-table
      :options.sync="tableOptions"
      :headers="tableHeaders"
      :items="events"
      :loading="busy"
      :server-items-length="totalEvents"
      :footer-props="{ showFirstLastPage: true, showCurrentPage: true, itemsPerPageOptions: pageSizes }"
      :expanded.sync="expandedRows"
      show-expand
      single-expand
      class="elevation-1"
    >
      <template v-slot:item.createdOn="{ item }">
        <div>{{ item.createdOn }}</div>
      </template>
      <template v-slot:item.executionTimeInSeconds="{ item }">
        <div>{{ Number(item.executionTimeInSeconds).toFixed(3) }} s</div>
      </template>
      <template v-slot:item.totalTimeInSeconds="{ item }">
        <div>{{ Number(item.totalTimeInSeconds).toFixed(3) }} s</div>
      </template>
      <template v-slot:item.successful="{ item }">
        <div :class="item.successful ? [] : ['error--text font-weight-bold']">{{ item.successful }}</div>
      </template>
      <template v-slot:item.error.message="{ item }">
        <div
          v-if="item.error"
          :class="{ 'error--text': !item.successful, 'text-truncate': truncateErrorText }"
          :title="item.error.message"
          style="width: 120px"
        >
          {{ item.error.message }}
        </div>
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td v-if="expandedRows && expandedRows.includes(item)" :colspan="headers.length" class="pa-0">
          <div class="pa-3">
            <v-textarea :value="prettyPrintObject(item)" rows="10" readonly style="font-size: 12px" />
          </div>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>
