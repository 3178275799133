<script>
import dayjs from 'dayjs'

export default {
  props: {
    fullName: { type: String, required: true },
    email: { type: String, required: true },
    lastLogin: { type: String },
    picture: { type: String },
    roles: { type: Array },
    birthday: { type: Object },
    carerUserId: { type: String },
    dependents: { type: Array },
  },

  computed: {
    lastLoginFormatted() {
      return this.lastLogin ? dayjs(this.lastLogin).fromNow() : ''
    },

    labels() {
      const labels = []

      this.roles.forEach((role) =>
        labels.push({
          text: `role: ${role}`,
          color: 'primary',
        })
      )

      if (this.carerUserId) {
        labels.push({
          text: 'Has carer',
          color: 'success',
        })
      }

      if (this.dependents && this.dependents.length > 0) {
        labels.push({
          text: `Has ${this.dependents.length} dependents`,
          color: 'warning',
        })
      }

      return labels
    },
  },
}
</script>

<template>
  <v-card>
    <div class="d-flex flex-no-wrap align-center justify-space-between">
      <div>
        <v-card-title v-text="fullName"></v-card-title>
        <v-card-subtitle v-text="email" style="word-break: break-all" class="pb-2"></v-card-subtitle>

        <v-card-text>
          <div class="d-flex align-center">
            <v-icon size="16" class="mr-1">mdi-login</v-icon>
            <span v-if="lastLoginFormatted"> {{ lastLoginFormatted }}</span>
            <strong v-else>No login yet</strong>
          </div>

          <div class="d-flex align-center">
            <v-icon size="16" class="mr-1">mdi-cake-variant-outline</v-icon>
            <span v-if="birthday">{{ birthday.day }} {{ birthday.month }}</span>
            <strong v-else>No birthday yet</strong>
          </div>

          <div>
            <v-chip-group column>
              <v-chip v-for="label in labels" :key="`${label.text}-${label.color}`" :color="label.color" pill outlined>
                {{ label.text }}
              </v-chip>
            </v-chip-group>
          </div>
        </v-card-text>
      </div>
      <v-avatar class="ma-3" size="40" tile>
        <v-img v-if="picture" :src="picture"></v-img>
      </v-avatar>
    </div>
  </v-card>
</template>
