<script>
import store from '@/infrastructure/store'
import useAsyncLoading from '@/infrastructure/apis/asyncLoadingComposable'
import { onMounted, toRefs } from '@vue/composition-api'
import { reactiveFormatRelativeTimeAgo } from '@/infrastructure/dates/dateFormatting'
import FeedbackReplyForAdminListItem from '@/infrastructure/userFeedbacks/components/FeedbackReplyForAdminListItem'

export default {
  components: { FeedbackReplyForAdminListItem },
  props: {
    creatorUserId: { type: String, required: true },
    createdOn: { type: String, required: true },
    message: { type: String, required: true },
    replies: { type: Array },
  },

  setup(props) {
    const createdOnRelativeTimeAgo = reactiveFormatRelativeTimeAgo(toRefs(props).createdOn)

    const { isBusy: isLoading, execute: fetchUserDisplaySummaries } = useAsyncLoading({
      actionFactory: () =>
        store.dispatch('localCache/fetchUserDisplaySummaries', {
          userIds: [props.creatorUserId],
        }),
    })

    onMounted(fetchUserDisplaySummaries)

    return {
      createdOnRelativeTimeAgo,
      isLoading,
    }
  },

  computed: {
    creatorUserDisplaySummary() {
      return store.getters['localCache/getUserDisplaySummaryById'](this.creatorUserId)
    },
  },
}
</script>

<template>
  <v-card>
    <v-card-subtitle v-if="creatorUserDisplaySummary" class="d-flex align-center">
      <v-avatar size="16" class="mr-1">
        <v-img :src="creatorUserDisplaySummary.picture"></v-img>
      </v-avatar>
      <div>{{ creatorUserDisplaySummary.fullName }}</div>

      <v-spacer />

      <div>{{ createdOnRelativeTimeAgo }}</div>
    </v-card-subtitle>

    <v-card-title class="py-0 text-pre-line" style="word-break: break-word">
      <i>{{ message }}</i>
    </v-card-title>

    <div class="pa-5">
      <v-expansion-panels v-if="replies && replies.length > 0">
        <v-expansion-panel>
          <v-expansion-panel-header> {{ replies.length }} replies</v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list two-line class="pa-0 ma-0">
              <template v-for="(reply, i) in replies">
                <v-divider v-if="i > 0" :key="`divider-${i}`" />

                <FeedbackReplyForAdminListItem
                  :key="reply.replyId"
                  :user-full-name="reply.userFullName"
                  :reply-time="reply.replyTime"
                  :message="reply.message"
                  class="pa-0 ma-0"
                />
              </template>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card v-else>
        <v-card-text>No replies yet</v-card-text>
      </v-card>
    </div>
  </v-card>
</template>
