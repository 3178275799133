<script>
import useAsyncLoading from '@/infrastructure/apis/asyncLoadingComposable'
import store from '@/infrastructure/store'
import { onMounted } from '@vue/composition-api'
import AdminUser from '@/infrastructure/admin/components/AdminUser'

export default {
  components: { AdminUser },
  setup() {
    const { isBusy: isLoading, execute: fetchUsers } = useAsyncLoading({ actionFactory: () => store.dispatch('admin/fetchUsers') })

    onMounted(fetchUsers)

    return {
      isLoading,
    }
  },

  computed: {
    users: () => store.state.admin.users,
  },
}
</script>

<template>
  <div>
    <v-card v-if="isLoading">
      <v-card-text>
        <v-progress-linear indeterminate />
      </v-card-text>
    </v-card>

    <AdminUser
      v-for="user in users"
      :key="user.userId"
      :full-name="user.fullName"
      :email="user.email"
      :last-login="user.lastLogin"
      :picture="user.picture"
      :roles="user.roles"
      :birthday="user.birthday"
      :carer-user-id="user.carerUserId"
      :dependents="user.dependents"
      class="mt-3"
    />
  </div>
</template>
