var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_vm._t("title-slot",null,null,{ busy: _vm.busy, loadData: _vm.loadData }),_c('v-data-table',{staticClass:"elevation-1",attrs:{"options":_vm.tableOptions,"headers":_vm.tableHeaders,"items":_vm.events,"loading":_vm.busy,"server-items-length":_vm.totalEvents,"footer-props":{ showFirstLastPage: true, showCurrentPage: true, itemsPerPageOptions: _vm.pageSizes },"expanded":_vm.expandedRows,"show-expand":"","single-expand":""},on:{"update:options":function($event){_vm.tableOptions=$event},"update:expanded":function($event){_vm.expandedRows=$event}},scopedSlots:_vm._u([{key:"item.createdOn",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.createdOn))])]}},{key:"item.executionTimeInSeconds",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(Number(item.executionTimeInSeconds).toFixed(3))+" s")])]}},{key:"item.totalTimeInSeconds",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(Number(item.totalTimeInSeconds).toFixed(3))+" s")])]}},{key:"item.successful",fn:function(ref){
var item = ref.item;
return [_c('div',{class:item.successful ? [] : ['error--text font-weight-bold']},[_vm._v(_vm._s(item.successful))])]}},{key:"item.error.message",fn:function(ref){
var item = ref.item;
return [(item.error)?_c('div',{class:{ 'error--text': !item.successful, 'text-truncate': _vm.truncateErrorText },staticStyle:{"width":"120px"},attrs:{"title":item.error.message}},[_vm._v(" "+_vm._s(item.error.message)+" ")]):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [(_vm.expandedRows && _vm.expandedRows.includes(item))?_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('div',{staticClass:"pa-3"},[_c('v-textarea',{staticStyle:{"font-size":"12px"},attrs:{"value":_vm.prettyPrintObject(item),"rows":"10","readonly":""}})],1)]):_vm._e()]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }