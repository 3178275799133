<script>
import CommandExecutionEventCard from '@/infrastructure/admin/components/CommandExecutionEventCard'
import debounce from '@/infrastructure/userInput/debounce'

export default {
  components: {
    CommandExecutionEventCard,
  },

  data() {
    return {
      filters: [
        { title: 'Failed', filterProps: { filterSuccessful: false } },
        { title: 'Long running', filterProps: { minimumTotalDuration: 2 } },
      ],

      selectedFilters: [],

      textSearch: '',
      debouncedTextSearch: '',
      setDebouncedTextSearch: debounce((val) => (this.debouncedTextSearch = val), 500),

      truncateErrorText: true,
    }
  },

  computed: {
    combinedFilters() {
      // noinspection UnnecessaryLocalVariableJS
      const combined = Object.assign({}, ...this.selectedFilters.map((f) => f.filterProps))
      // console.debug('combined', combined)
      return combined
    },
  },

  watch: {
    textSearch(to) {
      this.setDebouncedTextSearch(to)
    },
  },
}
</script>

<template>
  <div>
    <CommandExecutionEventCard
      v-if="combinedFilters"
      :filter-props="combinedFilters"
      :truncate-error-text="truncateErrorText"
      :text-search="debouncedTextSearch"
    >
      <template v-slot:title-slot="{ busy: busyLoadingEvents, loadData }">
        <v-card-title class="pb-0">
          <div class="mx-auto">Command Execution Events</div>
        </v-card-title>

        <v-card-title class="d-flex pt-0">
          <div class="text-left" style="width: 30px">
            <v-icon small>mdi-filter</v-icon>
          </div>
          <v-chip-group v-model="selectedFilters" multiple active-class="orange white--text">
            <v-chip v-for="filterOption in filters" :key="filterOption.title" :value="filterOption">
              {{ filterOption.title }}
            </v-chip>
          </v-chip-group>

          <v-spacer />

          <div class="mr-3" style="width: 300px">
            <v-text-field v-model="textSearch" dense solo placeholder="Search" hide-details :loading="busyLoadingEvents" :readonly="busyLoadingEvents" />
          </div>

          <v-chip :input-value="truncateErrorText" filter class="mr-3" @click="truncateErrorText = !truncateErrorText"> Truncate error text </v-chip>

          <v-btn :loading="busyLoadingEvents" :disabled="busyLoadingEvents" fab x-small @click="loadData">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-card-title>
      </template>
    </CommandExecutionEventCard>
  </div>
</template>
