<script>
import { reactiveFormatRelativeTimeAgo } from '@/infrastructure/dates/dateFormatting'
import { toRefs } from '@vue/composition-api'

export default {
  props: {
    userFullName: { type: String, required: true },
    replyTime: { type: String, required: true },
    message: { type: String, required: true },
  },

  setup(props) {
    const replyTimeRelativeTimeAgo = reactiveFormatRelativeTimeAgo(toRefs(props).replyTime)

    return {
      replyTimeRelativeTimeAgo,
    }
  },
}
</script>

<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-subtitle class="d-flex align-center">
        <div>{{ userFullName }}</div>
        <v-spacer />
        <div>{{ replyTimeRelativeTimeAgo }}</div>
      </v-list-item-subtitle>

      <v-list-item-title style="text-overflow: unset; white-space: normal; line-height: 1.4">
        <strong>{{ message }}</strong>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>
