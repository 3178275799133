<script>
import useAsyncLoading from '@/infrastructure/apis/asyncLoadingComposable'
import store from '@/infrastructure/store'
import { onMounted } from '@vue/composition-api'
import FeedbackCardForAdmin from '@/infrastructure/userFeedbacks/components/FeedbackCardForAdmin'

export default {
  components: { FeedbackCardForAdmin },

  setup() {
    const { isBusy: isLoading, execute: fetchUserFeedbacks } = useAsyncLoading({
      actionFactory: () => store.dispatch('userFeedbacks/fetchUserFeedbacksForAdmin'),
    })

    onMounted(fetchUserFeedbacks)

    return {
      isLoading,
    }
  },

  computed: {
    userFeedbacks: () => store.state.userFeedbacks.userFeedbacksForAdmin,
  },
}
</script>

<template>
  <div>
    <v-card v-if="isLoading">
      <v-card-text>
        <v-progress-linear indeterminate />
      </v-card-text>
    </v-card>

    <v-card v-else-if="!isLoading && (!userFeedbacks || userFeedbacks.length === 0)">
      <v-card-text>No feedback yet</v-card-text>
    </v-card>

    <FeedbackCardForAdmin
      v-for="feedback in userFeedbacks"
      :key="feedback.id"
      :creator-user-id="feedback.creatorUserId"
      :created-on="feedback.createdOn"
      :message="feedback.message"
      :replies="feedback.replies"
      class="mt-3"
    />
  </div>
</template>
